<template>
  <div>
    <van-nav-bar title="我的任务服务费" left-arrow @click-left="onClickLeft" />
    <div class="topBox flexBox">
      <div class="left">
        合计（元）
        <div class="totalNumber">{{ totalSalary | money }}</div>
      </div>
      <div class="line"></div>
      <div class="right" @click="showDatetimePicker = !showDatetimePicker">
        <span class="date"> {{ nowDate }}</span>
        <van-icon name="arrow-down" />
      </div>
    </div>

    <div class="listWrap">
      <van-list
        class="list"
        v-model="loading"
        :finished="finished"
        :finished-text="list.length === 0 ? '' : '已加载全部'"
        :immediate-check="false"
        @load="getList"
      >
        <div class="listItem flexBox" v-for="item in list" :key="item.saleSalaryId" @click="goDetail(item)">
          <div class="left">
            <div class="number">{{ item.paidInMoney | money }}</div>
            <div class="label">服务费（元）</div>
          </div>
          <div class="line"></div>
          <div class="right">
            <div class="title">{{ item.positionName }}</div>
            <div class="date">{{ item.sendDate }}</div>
          </div>
          <div class="icon">
            <van-icon name="arrow" />
          </div>
        </div>
        <van-empty v-if="list.length === 0">
          <div style="color: #ccc; font-size: 12px; text-align: center">
            <div style="font-size: 14px">没有找到任何记录</div>
            <div>试试查询其他的吧</div>
          </div>
        </van-empty>
      </van-list>
    </div>

    <!-- 时间选择器 -->
    <van-action-sheet v-model="showDatetimePicker" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="confirm"
          @cancel="cancel"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { taskCharge } from '@/api/charge'
import moment from 'moment'

export default {
  name: 'chargeList',
  data() {
    return {
      nowDate: '2023-01',
      loading: false,
      finished: false,
      totalSalary: 0,
      list: [],

      showDatetimePicker: false,

      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
      currentDate: new Date()
    }
  },
  created() {
    const empPk = this.getUrlData().empPk
    sessionStorage.setItem('empPk', empPk)
    const lastMoth = moment().subtract(1, 'M')
    this.nowDate = lastMoth.format('YYYY-MM')
    this.currentDate = new Date(lastMoth)
  },
  activated() {
    this.getList()
  },
  methods: {
    // 获取url传参
    getUrlData() {
      var url = window.location.search
      var obj = {}
      var reg = /[?&][^?&]+=[^?&]+/g
      var arr = url.match(reg)
      if (arr) {
        arr.forEach(function (item) {
          var tempArr = item.substring(1).split('=')
          var key = decodeURIComponent(tempArr[0])
          var val = decodeURIComponent(tempArr[1])
          obj[key] = val
        })
        return obj
      }
    },
    onClickLeft() {
      location.href = 'javascript:history.back();'
      // Toast("返回");
    },

    getList() {
      const empPk = sessionStorage.getItem('empPk')
      if (!empPk) return
      this.loading = true
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      taskCharge({ month: this.nowDate })
        .then(({ data: res }) => {
          console.log('res: ', res)
          Toast.clear()
          this.loading = false
          this.finished = true
          if (res.success) {
            this.totalSalary = res.data.totalSalary || 0
            this.list = res.data.salary || []
          }
        })
        .catch((err) => {
          console.log('err: ', err)
          Toast.clear()
          this.finished = true
          this.loading = false
        })
    },

    // 时间选择器点击确定
    confirm(value) {
      this.pageNum = 1
      this.nowDate = moment(value).format('YYYY-MM')
      this.getList()
      this.showDatetimePicker = false
    },
    // 时间选择器点击取消
    cancel() {
      this.showDatetimePicker = false
    },
    // 时间选择器默认功能勿动
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },

    goDetail(data) {
      const { saleSalaryId, terminalCode, projectCode } = data
      this.$router.push({
        path: '/chargeDetail',
        query: { saleSalaryId, terminalCode, projectCode }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topBox {
  background-color: #fff;
  box-sizing: border-box;
  height: 80px;
  padding: 0 20px;
  font-size: 14px;
  color: #333;
  .left {
    display: flex;
    flex-direction: column;
    .totalNumber {
      font-size: 24px;
      font-weight: bold;
      margin-top: 5px;
      color: #0099ff;
    }
  }
  .right {
    .date {
      margin-right: 10px;
    }
  }
  .line {
    height: 30px;
    width: 0;
    border-left: 1px solid #ececec;
  }
}

.listWrap {
  margin-top: 15px;
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.listItem {
  box-sizing: border-box;
  padding: 20px 15px;
  background-color: #fff;
  margin: 0 10px 10px;
  border-radius: 6px;

  .line {
    height: 30px;
    border-left: 1px solid #ececec;
    flex-shrink: 0;
  }

  .icon {
    flex-shrink: 0;
    color: #999;
  }

  .left {
    min-width: 100px;
    box-sizing: border-box;
    padding-right: 10px;
    // flex-shrink: 0;
    .number {
      font-size: 16px;
      font-weight: bold;
      color: #0099ff;
    }
    .label {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }
  }
  .right {
    flex: 1;
    padding: 0 20px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      word-break: break-all;
    }
    .date {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }
  }
}
</style>
